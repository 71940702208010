import React from 'react'
import { useIntl } from 'react-intl'
import style from './index.module.less'
import {
    choose_beampro
} from '@/images/beampro/index'
import BeamProLogo from '@/images/SVG/xreal_beampro_logo.svg'

const Specs = () => {
    const intl = useIntl()

    const specs_list = [
        {
            title: "beampro_block_specs_piece1_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece1_desc1"
                }
            ]
        },
        {
            title: "beampro_block_specs_piece2_title",
            desc: [
                {
                    title: "beampro_block_specs_piece2_desc2",
                    desc: "beampro_block_specs_piece2_desc1"
                }, {
                    title: "beampro_block_specs_piece2_desc4",
                    desc: "beampro_block_specs_piece2_desc3"
                }
            ]
        }, {
            title: "beampro_block_specs_piece3_title",
            desc: [
                {
                    title: "beampro_block_specs_piece3_desc1",
                    desc: "beampro_block_specs_piece3_desc2"
                },
                {
                    title: "beampro_block_specs_piece3_desc3",
                    desc: "beampro_block_specs_piece3_desc4"
                }, {
                    title: "beampro_block_specs_piece3_desc5",
                    desc: "beampro_block_specs_piece3_desc6"
                }
            ]
        }, {
            title: "beampro_block_specs_piece4_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece4_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece5_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc5"
                },
                {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc6"
                }
            ]
        }, {
            title: "beampro_block_specs_piece6_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece6_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece7_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece7_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece8_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece8_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece9_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece10_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece10_desc1"
                }, {
                    title: "beampro_block_specs_piece10_desc2",
                    desc: "beampro_block_specs_piece10_desc3"
                }, {
                    title: "beampro_block_specs_piece10_desc4",
                    desc: "beampro_block_specs_piece10_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece11_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc4"
                }
            ]
        }, {
            title: "beampro_block_specs_piece12_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece12_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece12_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece13_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece13_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece13_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece14_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece14_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece15_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece16_title",
            desc: [
                {
                    title: "beampro_block_specs_piece16_desc1",
                    desc: "beampro_block_specs_piece16_desc2"
                }, {
                    title: "beampro_block_specs_piece16_desc3",
                    desc: [
                        "beampro_block_specs_piece16_desc4",
                        "beampro_block_specs_piece16_desc5",
                    ]
                }
            ]
        }, {
            title: "beampro_block_specs_piece17_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece17_desc1"
                }, {
                    title: "beampro_block_specs_piece17_desc2",
                    desc: "beampro_block_specs_piece17_desc3",
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc4",
                        "beampro_block_specs_piece17_desc5",
                    ],
                    desc: "beampro_block_specs_piece17_desc6",
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc7"
                    ],
                    desc: "beampro_block_specs_piece17_desc8"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc9"
                    ],
                    desc: "beampro_block_specs_piece17_desc10"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc11",
                        "beampro_block_specs_piece17_desc12",
                    ],
                    desc: "beampro_block_specs_piece17_desc13"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc14"
                    ],
                    desc: "beampro_block_specs_piece17_desc15"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc16"
                    ],
                    desc: "beampro_block_specs_piece17_desc17"
                }
            ]
        }, {
            title: "beampro_block_specs_piece18_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc5"
                },
            ]
        }, {
            title: "beampro_block_specs_piece19_title",
            desc: [
                {
                    title: "beampro_block_specs_piece19_desc1",
                    desc: "beampro_block_specs_piece19_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece20_title",
            desc: [
                {
                    title: "beampro_block_specs_piece20_desc1",
                    desc: "beampro_block_specs_piece19_desc2"
                }, {
                    title: "beampro_block_specs_piece20_desc3",
                    desc: [
                        "beampro_block_specs_piece20_desc4", "beampro_block_specs_piece20_desc5"
                    ]
                }
            ]
        }, {
            title: "beampro_block_specs_piece21_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece21_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece22_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc5"
                },
            ]
        }, {
            title: "beampro_block_specs_piece23_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece23_desc1"
                }
            ]
        },


    ]

    return (
        <div className={style.box}>
            <img src={choose_beampro} />
            <div className={style.proName}><BeamProLogo /></div>
            <div className={style.spec}>
                {specs_list.map((item, index) => (
                    <div className={style.specItem} key={`beampro_spec_${index}`} style={{alignItems:!item.desc.title&&!item.desc.thick_title&&item.desc.length==1?'center':'flex-start'}}>
                        <div className={style.specItemType}>
                            {intl.formatMessage({ id: item.title })}

                        </div>
                        <div className={style.specItemContent}>
                            {item.desc.map((value, key) => (
                                <div className={style.specItemContentValue} key={`beampro_spec_${index}_value${key}`} style={{marginTop:!!value.title||!!value.thick_title?'36px':'8px'}}>
                                    {/* 是否有小标题 */}
                                    {!!value.title && <div className={style.specItemContentValueTitle}>{intl.formatMessage({ id: value.title })}</div>}
                                    {/* 是否有加粗标题 */}
                                    {!!value.thick_title&& value.thick_title.map((thickItem,thickIndex)=>(
                                        <div className={style.specItemContentValueThick} key={`beampro_spec_${index}_value${key}_thickItem${thickIndex}`}>{intl.formatMessage({ id: thickItem })}</div>
                                    ))}
                                   
                                    {/* 描述是否有很多选项 还是只有一条 */}
                                    {Array.isArray(value.desc) ? value.desc.map((descItem, descIndex) => (
                                        <div className={style.specItemContentValueDesc} key={`beampro_spec_${index}_value${key}_desc${descIndex}`}>{intl.formatMessage({ id: descItem })}</div>
                                    )) : <div className={style.specItemContentValueDesc}>{intl.formatMessage({ id: value.desc })}</div>}


                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Specs