import logo1 from './app_logo/logo1.png'
import logo2 from './app_logo/logo2.png'
import logo3 from './app_logo/logo3.png'
import logo4 from './app_logo/logo4.png'
import logo5 from './app_logo/logo5.png'
import logo6 from './app_logo/logo6.png'
import logo7 from './app_logo/logo7.png'
import logo8 from './app_logo/logo8.png'
import logo9 from './app_logo/logo9.png'
import logo10 from './app_logo/logo10.png'
import logo11 from './app_logo/logo11.png'
import logo12 from './app_logo/logo12.png'
import logo13 from './app_logo/logo13.png'
import logo14 from './app_logo/logo14.png'
import logo15 from './app_logo/logo15.png'
import logo16 from './app_logo/logo16.png'
import logo17 from './app_logo/logo17.png'
import logoplace from './app_logo/logo_place.png'
import google1 from './app_logo/google1.png'
import google2 from './app_logo/google2.png'
import google3 from './app_logo/google3.png'
import google4 from './app_logo/google4.png'
import google5 from './app_logo/google5.png'
import google6 from './app_logo/google6.png'

import slogan1 from './millons.svg'
import slogan2 from './ofapps.svg'
import slogan3 from './inar.svg'
import slogan4 from './glasses.svg'

const beampro_header_pro=`${process.env.resourceUrl}images/beampro/beampro_header_pro.png`
const beampro_two_model1=`${process.env.resourceUrl}images/beampro/beampro_two_model1.jpg`
const beampro_two_model2=`${process.env.resourceUrl}images/beampro/beampro_two_model2.jpg`
const nebulaOS_bg=`${process.env.resourceUrl}images/beampro/nebulaOS_bg.jpg`
const moment_black_record=`${process.env.resourceUrl}images/beampro/moment_black_record.jpg`
const compare_1_1=`${process.env.resourceUrl}images/beampro/black_compare1_1.jpg`
const compare_1_2=`${process.env.resourceUrl}images/beampro/black_compare1_2.jpg`
const compare_2_1=`${process.env.resourceUrl}images/beampro/black_compare2_1.jpg`
const compare_2_2=`${process.env.resourceUrl}images/beampro/black_compare2_2.jpg`
const entertainment=`${process.env.resourceUrl}images/beampro/entertainment.jpg`
const entertainment_jp=`${process.env.resourceUrl}images/beampro/entertainment_jp.jpg`
const entertainment_left=`${process.env.resourceUrl}images/beampro/entertainment_left.jpg`
const entertainment_gaming=`${process.env.resourceUrl}images/beampro/entertainment_gaming.jpg`
const moment_beam=`${process.env.resourceUrl}images/beampro/moment_beam.jpg`
const moment_beam_line=`${process.env.resourceUrl}images/beampro/moment_beam_line.webp`
const moment_beam_50=`${process.env.resourceUrl}images/beampro/moment_beam_50.png`
const moment_beam_mobile=`${process.env.resourceUrl}images/beampro/moment_beam_mobile.jpg`
const moment_beam_line_mobile=`${process.env.resourceUrl}images/beampro/moment_beam_line_mobile.jpg`
const moment_beam_50_mobile=`${process.env.resourceUrl}images/beampro/moment_beam_50_mobile.png`
const sofa3d_video = `${process.env.resourceUrl}images/beampro/sofa3d.mp4`
const highlight_video1 = `${process.env.resourceUrl}images/beampro/highlight_video1.mp4`
const highlight_video2 = `${process.env.resourceUrl}images/beampro/highlight_video2.mp4`
const highlight_video3 = `${process.env.resourceUrl}images/beampro/highlight_video3.mp4`
const highlight_video4 = `${process.env.resourceUrl}images/beampro/highlight_video4.mp4`

const entertainment_video = `${process.env.resourceUrl}images/beampro/entertainment_video.mp4`
const companion_pic1=`${process.env.resourceUrl}images/beampro/companion_pic1.jpg`
const companion_pic2=`${process.env.resourceUrl}images/beampro/companion_pic2.jpg`
const companion_pic3=`${process.env.resourceUrl}images/beampro/companion_pic3.jpg`
const companion_pic4=`${process.env.resourceUrl}images/beampro/companion_pic4.jpg`
const companion_pic5=`${process.env.resourceUrl}images/beampro/companion_pic5.jpg`
const parts_2=`${process.env.resourceUrl}images/beampro/parts_2.png`
const parts_1=`${process.env.resourceUrl}images/beampro/parts_1.png`
const choose_beampro=`${process.env.resourceUrl}images/beampro/choose_beampro.png`
const choose_beampro_air2ultra=`${process.env.resourceUrl}images/beampro/choose_beampro_air2ultra.png`
const choose_beampro_air2pro=`${process.env.resourceUrl}images/beampro/choose_beampro_air2pro.png`
const sale_man=`${process.env.resourceUrl}images/beampro/sale_man.png`
const enriching_pc=`${process.env.resourceUrl}images/beampro/enriching_pc.png`
const enriching_mobile=`${process.env.resourceUrl}images/beampro/enriching_mobile.png`
const enriching_pc_jp=`${process.env.resourceUrl}images/beampro/enriching_pc_jp.png`
const enriching_mobile_jp=`${process.env.resourceUrl}images/beampro/enriching_mobile_jp.png`
const business_pc=`${process.env.resourceUrl}images/beampro/business_pc.jpg`
const business_mobile=`${process.env.resourceUrl}images/beampro/business_mobile.jpg`
const user_guide=`${process.env.resourceUrl}images/beampro/beampro_userguide.mp4`
const user_guide_poster=`${process.env.resourceUrl}images/beampro/user_guide_poster.jpg`
const beampro_model=`${process.env.resourceUrl}images/beampro/beampro_model.jpg`
const beampro_model_mobile=`${process.env.resourceUrl}images/beampro/beampro_model_mobile.webp`
const entertainment_girl=`${process.env.resourceUrl}images/beampro/entertainment_girl.jpg`
const beampro_3d_pic=`${process.env.resourceUrl}images/beampro/beampro_3d_pic.jpg`
const beampro_3d_beampro =`${process.env.resourceUrl}images/beampro/beampro_3d_beampro.webp`
const beampro_3d_video1 =`${process.env.resourceUrl}images/beampro/beampro_3d_video1.mp4`
const beampro_3d_video2 =`${process.env.resourceUrl}images/beampro/beampro_3d_video2.mp4`
const beampro_3d_hand =`${process.env.resourceUrl}images/beampro/beampro_3d_hand.png`

export{
    beampro_model_mobile,
    beampro_3d_video1,
    beampro_3d_video2,
    beampro_3d_hand,
    beampro_3d_beampro,
    beampro_3d_pic,
    entertainment_girl,
    beampro_model,
    user_guide_poster,
    user_guide,
    enriching_mobile_jp,
    enriching_pc_jp,
    business_mobile,
    business_pc,
    enriching_mobile,
    enriching_pc,
    sale_man,
    choose_beampro,
    choose_beampro_air2ultra,
    choose_beampro_air2pro,
    parts_1,
    parts_2,
    companion_pic1,
    companion_pic2,
    companion_pic3,
    companion_pic4,
    companion_pic5,
    moment_beam_mobile,
    moment_beam_line_mobile,
    moment_beam_50_mobile,
    moment_beam,
    moment_beam_line,
    moment_beam_50,
    entertainment_gaming,
    entertainment_video,
    entertainment_left,
    entertainment,
    compare_1_1,
    compare_1_2,
    compare_2_1,
    compare_2_2,
    moment_black_record,
    sofa3d_video,
    nebulaOS_bg,
    beampro_header_pro,
    beampro_two_model1,
    beampro_two_model2,
    entertainment_jp,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logoplace,
    google1,
    google2,
    google3,
    google4,
    google5,
    google6,
    slogan1,
    slogan2,
    slogan3,
    slogan4,
    highlight_video1,
    highlight_video2,
    highlight_video3,
    highlight_video4
}