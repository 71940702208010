import React, { useEffect, useRef } from 'react'
import style from './index.module.less'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { entertainment_video, entertainment_gaming } from '@/images/beampro/index'
import logo1 from '@/images/beampro/app_logo/logo4.png'
import logo2 from '@/images/beampro/app_logo/logo8.png'
import logo3 from '@/images/beampro/app_logo/logo9.png'
import logo4 from '@/images/beampro/app_logo/logo13.png'
import logo5 from '@/images/beampro/app_logo/logo14.png'
import logo6 from '@/images/beampro/app_logo/logo11.png'
import logo7 from '@/images/beampro/app_logo/logo6.png'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"



const UseMobile = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const logo_arr = [logo1, logo2, logo3, logo4, logo5, logo6, logo7]
    const logo_animation = [
        { scale: 1, y: '50%' },
        { scale: 1.2, y: '-5%' },
        { scale: 1.1, y: '35%' },
        { scale: 1.08, y: '20%' },
        { scale: 1.05, y: '60%' },
        { scale: 1, y: '0' },
        { scale: 1.1, y: '30%' },
    ]
    useEffect(() => {
     
        const logoboxarr = gsap.utils.toArray('.beampro_watch_logo')
        logoboxarr.forEach((item, index) => {
            logo_gsap(item, logo_animation[index], ifPc)
        })

    }, [])
    const logo_gsap = (item, index, ifPc) => {
        const scrollTriggerSettings = {
            trigger: ifPc ? '.beamUseBox' : '.beamUseBoxMobileItem',
            start: ifPc ? '40% top' : "20% bottom",
            end: "bottom 80%",
            scrub: true,
        };

        gsap.to(item, {
            scale: index.scale,
            top: index.y,
            scrollTrigger: scrollTriggerSettings,
            ease: 'power1.inOut',
        })
    }

    return (
        <div className={style.boxM}>
            <div className={style.contentM}>
                <div className={style.title}>
                    {intl.formatMessage({ id: `beampro_part4_text1` })}
                </div>
                <div className={style.desc}>
                    {intl.formatMessage({ id: `beampro_part4_text2` })}
                </div>
                <div className={style.contentMBox}>
                    <video autoPlay playsInline loop muted width="100%" >
                        <source src={entertainment_video} type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className={style.contentM}>
                <div className={style.title}>
                    {intl.formatMessage({ id: `beampro_part4_text4` })}
                </div>
                <div className={style.desc}>
                    {intl.formatMessage({ id: `beampro_part4_text5` })}
                </div>
                <div className={style.contentMBox}>
                    <img src={entertainment_gaming} />
                </div>
            </div>
            <div className={style.contentM}>
                <div className={style.title}>
                    {intl.formatMessage({ id: `beampro_part4_text6` })}
                </div>
                <div className={style.desc} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `beampro_part4_text7` })}` }}>
                </div>
                <div className={classNames(style.contentMBox, 'beamUseBoxMobileItem')}  style={{marginTop:'30px'}}>
                    {logo_arr.map((item, index) => (
                        <div className={classNames(style.logoItem, 'beampro_watch_logo')} key={`beampro_watch_${index}`}>
                            <img src={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    

    )
}
export default UseMobile