import React from "react";
import style from './index.module.less'
import {
    choose_beampro,
    choose_beampro_air2ultra,
    choose_beampro_air2pro,
} from '@/images/beampro/index'
import { useIntl } from "react-intl";
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import useNavigate from '@/hooks/useNavigate'

const Choose = () => {
    const intl = useIntl()
    const region = getCountry()

    const list = [
        {
            img: choose_beampro,
            name: intl.formatMessage({ id: "beampro_part9_text1" }),
            name_tip: ' ',
            wifi128: buy_link[region]['beampro'].shop_price_128,
            wifi256: buy_link[region]['beampro'].shop_price_256,
            wifi128_sale: buy_link[region]['beampro'].sale_shop_price_128,
            wifi256_sale: buy_link[region]['beampro'].sale_shop_price_256,
            fiveG256: "$199",
            shop_link: buy_link[region]['beampro'].shop,
            amazon_link: "",
            key: "beampro"
        }, {
            img: choose_beampro_air2pro,
            name: intl.formatMessage({ id: "beampro_part9_text2" }),
            name_tip: intl.formatMessage({ id: "beampro_part9_text2_type1" }),
            wifi128: buy_link[region]['beampro_air2pro'].shop_price_128,
            wifi256: buy_link[region]['beampro_air2pro'].shop_price_256,
            wifi128_sale: buy_link[region]['beampro_air2pro'].sale_shop_price_128,
            wifi256_sale: buy_link[region]['beampro_air2pro'].sale_shop_price_256,
            fiveG256: "$199",
            shop_link: buy_link[region]['beampro_air2pro'].shop,
            amazon_link: "",
            key: "beampro_air2pro"

        }, {
            img: choose_beampro_air2pro,
            name: intl.formatMessage({ id: "beampro_part9_text2" }),
            name_tip: intl.formatMessage({ id: "beampro_part9_text2_type2" }),
            wifi128: buy_link[region]['beampro_air2'].shop_price_128,
            wifi256: buy_link[region]['beampro_air2'].shop_price_256,
            wifi128_sale: buy_link[region]['beampro_air2'].sale_shop_price_128,
            wifi256_sale: buy_link[region]['beampro_air2'].sale_shop_price_256,
            fiveG256: "$199",
            shop_link: buy_link[region]['beampro_air2'].shop,
            amazon_link: "",
            key: "beampro_air2"

        }
    ]
    return (
        <div className={style.box}>

            <div className={style.title}>{intl.formatMessage({ id: "beampro_part9_title" })}</div>
            <div className={style.choose}>
                <div className={style.chooseTable}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                {list.map((item, index) => (
                                    <th className={style.theadBox} key={`beampro_choose_thead${index}`}>
                                        <img src={item.img} />
                                        <div className={style.theadBoxName}>{item.name}</div>
                                        <div className={style.theadBoxNameTip}>{item.name_tip}</div>
                                    </th>
                                ))}
                            </tr>

                        </thead>
                        <div style={{ height: '80px' }} className={style.place}></div>
                        <tbody>
                            <tr>
                                <td className={style.priceType}>128G Wi-Fi</td>
                                {list.map((item, index) => (
                                    <td className={style.price} key={`beampro_choose_128price${index}`}>
                                        {!!item.wifi128_sale && <span>{item.wifi128_sale}</span>}&nbsp;
                                        <span style={{ textDecoration: !!item.wifi128_sale ? 'line-through' : 'unset', color: !!item.wifi256_sale ? 'rgba(0,0,0,0.4)' : '#000', fontWeight: !!item.wifi128_sale ? 200 : 500 }}>{item.wifi128}</span>
                                    </td>
                                ))}

                            </tr>
                            <tr>
                                <td className={style.priceType}>256G Wi-Fi</td>
                                {list.map((item, index) => (
                                    <td className={style.price} key={`beampro_choose_256price${index}`}>
                                        {!!item.wifi256_sale && <span>{item.wifi256_sale}</span>}&nbsp;
                                        <span style={{ textDecoration: !!item.wifi256_sale ? 'line-through' : 'unset', color: !!item.wifi256_sale ? 'rgba(0,0,0,0.4)' : '#000', fontWeight: !!item.wifi256_sale ? 200 : 500 }}>{item.wifi256}</span></td>
                                ))}
                            </tr>

                            <tr>
                                <td></td>
                                {list.map((item, index) => (
                                    <td className={style.buy} key={`beampro_choose_buy${index}`}>
                                        <div className={style.buyShop} onClick={() => {
                                            window.open(item.shop_link);
                                            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                                                custom: {
                                                    module_id: 20071,
                                                    product_name: item.key
                                                }
                                            });
                                        }}>
                                            {intl.formatMessage({ id: "buy_now" })}
                                        </div>
                                        {!!item.amazon_link && <div className={style.buyAmazon} onClick={() => window.open(item.amazon_link)}>
                                            {intl.formatMessage({ id: "buy_amazon" })}
                                        </div>}

                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>



            {region !== 'jp' && <div className={style.business}>
                {intl.formatMessage({ id: "beampro_business_entry" })}
                &nbsp;
                <div className={style.businessLearn} onClick={() => {
                    useNavigate('/business');
                    typeof sensors !== "undefined" && sensors.track('module_interaction', {
                        custom: {
                            module_id: 20076
                        }
                    });
                }}>  {intl.formatMessage({ id: `learn_more` })} → </div>
            </div>}

        </div>
    )
}
export default Choose